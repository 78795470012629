import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"

const Contact = ({ data, location }) => {
  const [submitted, setSubmitted] = useState(false)
  const submit = token => {}

  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact" />
      <h1>Contact our Community</h1>
      <p>
        We are a community of renters who want to make the world a better place
        by going solar. We are working to make solar more accessible to renters
        and to help renters save money on their energy bills.
      </p>
      <form method="post" action="https://formspree.io/email@domain.tld">
        <label>
          Email
          <input type="email" name="_replyto" />
          {/* prompt */}
          <input type="hidden" name="_subject" value="New submission!" />
          {/* subject line */}
          <input
            type="hidden"
            name="_next"
            value="https://solarforrenters.com/thanks"
          />
          {/* redirect URL */}
          <textarea name="message"></textarea>
          {/* form field */}
          <ReCaptcha
            action="homepage"
            siteKey="6Ld3RYAhAAAAAL843a0ENadhYHi4-jiQpr1JHU4Z"
            onVerify={token => submit(token)}
            submitted={submitted}
          />
          <button type="submit" onClick={() => setSubmitted(true)}>
            Send
          </button>
          {/* recaptcha */}
        </label>
      </form>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Contact
